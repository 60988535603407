<template>
  <div class="app">
    <div class="app-content">
      <h4>中国共产党第十九届中央委员会第三次全体会议公报</h4>
      <div class="heads">
        <div class="timeData">
          <span>时间：</span>
          <span class="commSyl">2023-12-31</span>
        </div>
        <div class="pageView">
          <span> 浏览次数：</span>
          <span class="commSyl"> 78187</span>
        </div>
        <div>
          <span>信息来源：</span>
          <span class="commSyl">接通公司</span>
        </div>
      </div>
      <div class="listSon">
        <div v-for="item in list" :key="item.id">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="thePrecedingAndSubsequentArticles">
      <div class="box_left">
        <span>上一篇：</span>
        <span class="son_lt">清分结算部</span>
      </div>
      <div class="box_right">
        <span>上一篇：</span>
        <span class="son_Rt">清分结算部</span>
      </div>
    </div>
    <BottomField></BottomField>
  </div>
</template>

<script>
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
export default {
  components: {
    BottomField,
  },
  data() {
    return {
      list: [
        {
          id: 0,
          title: `中国共产党第十九次全国代表大会（简称中共十九大）于2017年10月18日至10月24日在北京召开。这次大会的主题是：不忘初心，牢记使命，高举中国特色社会主义伟大旗帜，决胜全面建成小康社会，夺取新时代中国特色社会主义伟大胜利，为实现中华民族伟大复兴的中国梦不懈奋斗。
          党的十九大，是在全面建成小康社会决胜阶段、中国特色社会主义发展关键时期召开的一次十分重要的大会。承担着谋划决胜全面建成小康社会、深入推进社会主义现代化建设的重大任务，事关党和国家事业继往开来，事关中国特色社会主义前途命运，事关最广大人民根本利益。
          [1]
          2017年10月18日上午9:00，中国共产党第十九次全国代表大会在人民大会堂开幕。习近平代表第十八届中央委员会向大会作了题为《决胜全面建成小康社会
          夺取新时代中国特色社会主义伟大胜利》的报告。
          2017年10月24日，中国共产党第十九次全国代表大会在选举产生新一届中央委员会和中央纪律检查委员会，通过关于十八届中央委员会报告的决议、关于十八届中央纪律检查委员会工作报告的决议、关于《中国共产党章程（修正案）》的决议后，在人民大会堂胜利闭幕。
          [2-3]中国共产党第十九次全国代表大会（简称中共十九大）于2017年10月18日至10月24日在北京召开。这次大会的主题是：不忘初心，牢记使命，高举中国特色社会主义伟大旗帜，决胜全面建成小康社会，夺取新时代中国特色社会主义伟大胜利，为实现中华民族伟大复兴的中国梦不懈奋斗。
          党的十九大，是在全面建成小康社会决胜阶段、中国特色社会主义发展关键时期召开的一次十分重要的大会。承担着谋划决胜全面建成小康社会、深入推进社会主义现代化建设的重大任务，事关党和国家事业继往开来，事关中国特色社会主义前途命运，事关最广大人民根本利益。
          [1]
          2017年10月18日上午9:00，中国共产党第十九次全国代表大会在人民大会堂开幕。习近平代表第十八届中央委员会向大会作了题为《决胜全面建成小康社会
          夺取新时代中国特色社会主义伟大胜利》的报告。
          2017年10月24日，中国共产党第十九次全国代表大会在选举产生新一届中央委员会和中央纪律检查委员会，通过关于十八届中央委员会报告的决议、关于十八届中央纪律检查委员会工作报告的决议、关于《中国共产党章程（修正案）》的决议后，在人民大会堂胜利闭幕。
          [2-3]`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.commSyl {
  color: #c1c1c1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  .app-content {
    width: 80%;
    padding: 0 0.9375rem;
    background-color: #fff;
    border-radius: 0.25rem;
    height: 37.5rem;
    margin: 0 auto;
    h4 {
      width: 100%;
      height: 2.1875rem;
      line-height: 2.1875rem;
      color: #c50c11;
    }
    .heads {
      width: 100%;
      height: 2.8125rem;
      border-bottom: 0.0625rem solid #f9c4c6;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      .timeData {
        margin-right: 0.9375rem;
      }
      .pageView {
        margin-right: 0.9375rem;
      }
    }
    .listSon {
      width: 100%;
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0.9375rem;
      text-align: left;
    }
  }
  .thePrecedingAndSubsequentArticles {
    width: 80%;
    height: 2.8125rem;
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_left {
      font-size: 0.75rem;
      .son_lt {
        color: #c1c1c1;
      }
    }
    .box_right {
      font-size: 0.75rem;
      .son_Rt {
        color: #c1c1c1;
      }
    }
  }
}
</style>
